import { Square3Stack3DIcon } from "@heroicons/vue/20/solid";

export const understandProperties = {
  order: 0,
  name: "Understand properties",
  keywords: "property",
  subcategory: "Intro to properties",
  icon: Square3Stack3DIcon,
  iconColor: "text-emerald-400",
};
